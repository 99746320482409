import { CircularProgress, Typography } from '@mui/material';
import React from 'react';

// import imageLoader from '../assets/images/Liberty-loader.gif';

export const Loader: React.FC<any> = () => {
  return (
    <Typography
      component={'div'}
      sx={{
        left: ' 50%',
        position: 'fixed',
        top: ' 50%',
        transform: 'translate(-50%, -50%)',
      }}>
      <CircularProgress sx={{ color: '#340000' }} size={80} />
    </Typography>
  );
};
