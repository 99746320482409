import * as Yup from 'yup';

export const addNoteSchema = Yup.object().shape({
  notes: Yup.string().trim().required('Notes is required'),
});

export const editCandidateSchema = Yup.object().shape({
  city: Yup.string().required('City is required'),
  pocEmail: Yup.string().nullable().optional().email('Email is invalid'),
  pocUserId: Yup.string().nullable().optional(),
  state: Yup.string().required('State is required'),
  workAuthorization: Yup.string()
    .nullable()
    .required('Work Authorization is required'),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(20, 'Password must not exceed 20 characters'),
});

export const forgotSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
});

export const addAdminSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  empCode: Yup.string().required('Employee Code is required'),
  firstName: Yup.string().required('First Name is required'),
  ipStatus: Yup.boolean().notRequired(),
  lastName: Yup.string().required('Last Name is required'),
  organizationId: Yup.string().required('Company is required'),
  phoneNumber: Yup.string().notRequired(),
});

export const addSkillsSchema = Yup.object().shape({
  aliasName: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().notRequired(),
    }),
  ),
  name: Yup.string().trim().required('Skill Name is required'),
  primary: Yup.boolean().optional(),
});

export const addCustomerSchema = Yup.object().shape({
  name: Yup.string().trim().required('Customer Name is required'),
});

export const addIPWhitelistSchema = Yup.object().shape({
  ipName: Yup.string().trim().required('IP Address is required'),
});

export const editProfileSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  phoneNumber: Yup.string().notRequired(),
});

export const addNotesSchema = Yup.object().shape({
  note: Yup.string().required('Note is required'),
});

export const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Incorrect Password',
    ),
});

export const createPasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .when('password', {
      is: (password) => (password && password.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password doesn't match"),
    }),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Enter valid password',
    ),
});

export const changePasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required('Retype new password is required')
    .when('newPassword', {
      is: (newpassword) =>
        newpassword && newpassword.length > 0 ? true : false,
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        "Password doesn't match",
      ),
    }),
  currentPassword: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Password must contain one uppercase, one lowercase, one number and one special case character',
    ),
  newPassword: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Password must contain one uppercase, one lowercase, one number and one special case character',
    ),
});

declare module 'yup' {
  interface ArraySchema<T> {
    unique(field: string, message: string): ArraySchema<T>;
  }
}

Yup.addMethod(Yup.array, 'unique', function (field: string, message: string) {
  return this.test('unique', message, function (array) {
    const uniqueData = Array.from(
      new Set(array.map((row) => row[field]?.toLowerCase())),
    );
    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex(
      (row, i) => row[field]?.toLowerCase() !== uniqueData[i],
    );
    if (array[index][field] === '') {
      return true;
    }
    return this.createError({
      message,
      path: `${this.path}.${index}.${field}`,
    });
  });
});

export const AddAndEditJobSchema = Yup.object().shape({
  certifications: Yup.string().nullable().notRequired(),
  city: Yup.string().required('City is required'),
  customerId: Yup.string().required('Customer is required'),
  education: Yup.string().required('Education is required'),
  educationNotes: Yup.string().nullable().notRequired(),
  files: Yup.array().min(0, 'Upload job files').required('Upload job files'),
  interviewType: Yup.string().required('Interview type is required'),
  jobDurationFrom: Yup.number().notRequired(),
  jobDurationTo: Yup.number().notRequired(),
  jobTitle: Yup.string().required('Job title is required'),
  jobsSkills: Yup.array().of(
    Yup.object().shape({
      preffered: Yup.number().notRequired(),
      required: Yup.number().notRequired(),
      skillId: Yup.string().required('Skill is required'),
    }),
  ),
  notes: Yup.string().nullable().notRequired(),
  payRangeFrom: Yup.number().notRequired(),
  payRangeTo: Yup.number().notRequired(),
  payRateType: Yup.string().nullable().notRequired(),
  // position: Yup.string().required('Position is required'),
  positionType: Yup.string().required('Position type is required'),

  projectRole: Yup.string().required('Project role is required'),
  salaryDesired: Yup.number().notRequired(),
  startDate: Yup.string().nullable().required('Start date is required'),
  state: Yup.string().required('State is required'),
  workStatus: Yup.array()
    .min(1, 'Work status is required')
    .required('Work status is required'),
  // workStatus: Yup.string().required('Work status is required'),
  // workStatus: Yup.array()
  //   .min(0, 'Work status is required')
  //   .required('Work status is required'),
  workType: Yup.string().required('Work type is required'),
  zip: Yup.string().nullable().required('Zip is required'),
});
