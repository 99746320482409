import './style/style.scss';
import './style/datepicker.scss';

import LockIcon from '@mui/icons-material/Lock';
import LoadingToRedirect from 'components/LoadingToRedirect';
import MarketerRoutes from 'config/routes/MarketerRoutes';
import PrivateRoutes from 'config/routes/PrivateRoutes';
import PublicRoutes from 'config/routes/PublicRoutes';
import RecruiterRoutes from 'config/routes/RecruiterRoutes';
import SalesRoutes from 'config/routes/SalesRoutes';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { logout, ssoLogout } from 'store/actions/UserAction';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { USER_LOGIN_SUCCESS } from 'store/types/UserTypes';
import Storage from 'utils/Storage';

const App: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const {
    userInfo: userDetail,
    isRestricted,
    isPortalError,
  }: any = useAppSelector((store: any) => store.userLogin);
  const TokenStatus = Storage.getTokens();

  const logoutHandler = async () => {
    await ssoLogout();
    dispatch(logout(true));
    // // e.preventDefault();
    // dispatch({
    //   type: USER_LOGOUT,
    // });
    // localStorage.clear();
    // Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
    // Storage.removeItem(Storage.KEYS.MENUS);
  };

  const handleAppEntry = useCallback(() => {
    if (!_.isEmpty(TokenStatus)) {
      dispatch({
        payload: Storage.isTokenValidDetails(),
        type: USER_LOGIN_SUCCESS,
      });
    } else {
      searchParams.get('hashKey') === '' && navigate('/login');
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    handleAppEntry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isRestricted || isPortalError?.show) {
    return (
      <div className="main-container">
        <LockIcon sx={{ fontSize: '65px' }} />
        <div className="denied">Access to this application is restricted</div>
        <div className="f-18">
          {isPortalError?.message
            ? isPortalError?.message
            : 'Please check with your Administrator'}
        </div>
        <div className="back-to">
          <div>Back To</div>{' '}
          <span className="login" onClick={() => logoutHandler()}>
            {' '}
            Login{' '}
          </span>
        </div>
      </div>
    );
  }
  if (loading) {
    return <LoadingToRedirect />;
  }
  return !_.isEmpty(userDetail) ? (
    <div>
      {/* Admin logins */}
      {userDetail?.roleId === 1 && <PrivateRoutes />}

      {/* Recruiter logins */}
      {userDetail?.roleId === 3 && <RecruiterRoutes />}

      {/* Marketer logins */}
      {userDetail?.roleId === 4 && <MarketerRoutes />}

      {/* Sales logins */}
      {userDetail?.roleId === 5 && <SalesRoutes />}
    </div>
  ) : (
    <PublicRoutes />
  );
};

export default App;
