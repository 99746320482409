export const MASTER_ENDPOINT = {
  Admins: 'users',
  Candidates: 'candidates',
  Changepassword: 'password/change',
  Clients: 'clients',
  Consultants: 'consultants',
  Customer: 'customer',
  EmailValidate: 'auth/email-validation',
  Files: 'files',
  ForgotPassword: 'password/forgot',
  IpWhiteList: 'ip-white-list',
  Jobs: 'job-postings',
  LeaveTypes: 'master/leave-types',
  Leaves: 'leave',
  LeavesStatus: 'leave/status',
  Login: 'auth/login',
  LogoutUrl: 'auth/logout',
  MatchedCandidate: 'candidates/list',
  Organization: 'master/organization',
  Payperiods: 'payperiods',
  ProjectsConsultant: 'projects/project-consultant',
  Queue: 'queues',
  Roles: 'master/roles',
  SetPassword: 'password/set-password',
  Skills: 'skills',
  SsoLogin: 'auth/sso/login',
  ValidateHash: 'password/validate',
  VersionHistory: 'version-history',
  WorkStatus: 'master/work-status',
};

export const TIMESHEET = {
  Notification: 'notifications',
  TimeSheetNotesCreate: 'timesheets/notes',
  TimeSheetnotes: 'timesheets/timesheets-notes',
  TimeSheets: 'timesheets',
  TimeSheetsHours: 'timesheets/hours',
};

export const PAYSHEET: any = {
  Payroll: 'payroll',
};
